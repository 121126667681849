define("shop/tailwind/config/colors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
  |-------------------------------------------------------------------------------
  | Colors                                    https://tailwindcss.com/docs/colors
  |-------------------------------------------------------------------------------
  |
  | Here you can specify the colors used in your project. To get you started,
  | we've provided a generous palette of great looking colors that are perfect
  | for prototyping, but don't hesitate to change them for your project. You
  | own these colors, nothing will break if you change everything about them.
  |
  | We've used literal color names ("red", "blue", etc.) for the default
  | palette, but if you'd rather use functional names like "primary" and
  | "secondary", or even a numeric scale like "100" and "200", go for it.
  |
  */
  var _default = {
    transparent: 'transparent',
    'grey-darkest': '#2b2b2b',
    'grey-darker': '#3c3c3c',
    'grey-dark': '#565656',
    grey: '#989898',
    'grey-light': '#c0b8b2',
    'grey-lighter': '#eceae8',
    'grey-lightest': '#fbf8f6',
    black: '#101010',
    white: '#ffffff',
    red: '#e33010',
    green: '#199446',
    'green-lightest': '#ddefe3',
    blue: '#0000c0',
    'blue-light': '#0a22ff',
    smoke: 'rgba(0, 0, 0, .5)',
    'smoke-light': 'rgba(0, 0, 0, .1)',
    fog: 'rgba(255, 255, 255, .5)'
  };
  _exports.default = _default;
});