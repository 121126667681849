define("shop/translations/en", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "about": {
      "contact": "Contact",
      "content": {
        "caption": "Lunet was founded by brothers Ștefan and Alexandru in 2019\n",
        "p1": "We’ve been wearing glasses a long time. But for most of that time, it was no fun.\nWe thought that good glasses needed to be expensive. We thought we had to\nchoose from the boring frames available at the optometrist. We thought we had\nto wear the same frames as everybody else...\n",
        "p2": "The revelation came as we started doing some research. In the eyewear world, the average business\nmodel is outdated. The process simply has too many steps. So we decided to do things differently.\n",
        "p3": "We design the glasses ourselves, in our Bucharest office.\nThey are manufactured by experienced craftspeople and sold exclusively through our\nsignature stores and website. We deliver them directly to you, without further\nintermediaries. This way, we're able to cut unnecessary costs that\ncustomers otherwise pay for.\n"
      },
      "title": "Our story"
    },
    "accessories": {
      "clipOns": {
        "subtitle": "With a clip-on, you're worry free, not having to carry 2 pairs of glasses with you ever again.",
        "title": "Clip-on"
      }
    },
    "account": {
      "appointments": {
        "title": "Eye tests"
      },
      "btn": {
        "save": "Save changes",
        "signOut": "Sign out"
      },
      "label": {
        "email": "Email Address",
        "firstName": "First Name",
        "lastName": "Last Name",
        "phone": "Phone"
      },
      "orders": {
        "title": "Orders"
      },
      "prescription": {
        "label": {
          "add": "ADD",
          "axis": "AXIS",
          "cylinder": "CYL",
          "pupilDistance": "PD",
          "sphere": "SPH"
        },
        "title": "Prescription"
      },
      "title": "Account"
    },
    "alert": {
      "eyeTest": "Free eye-tests!",
      "schedule": "Book now"
    },
    "appointment": {
      "contactsNotice": "Avoid wearing contact lenses to your appointment. If that’s not possible,\nplease have your contact lens holder with you so that you can store\nand protect them during the eye exam.\n",
      "description": "We booked your appointment. We will send you a reminder one day prior to it.\nThe eye-test takes approximately 20 minutes. If you cannot make it,\nplease let us know at <a class=\"font-medium\" href=\"tel:{phone}\">{phone}</a>.\n",
      "ending": "See you soon!",
      "paymentNotice": "Please note that <strong>we only accept<br>\ncard payments</strong> in our stores.\n",
      "title": "You have booked your eye test!"
    },
    "archive": {
      "nav": {
        "glasses": "Glasses",
        "sunglasses": "Sunglasses"
      },
      "subtitle": "Steal the spotlight with 50% off on select styles – limited time, limited frames!",
      "title": "Archive Sale"
    },
    "cart": {
      "autoDiscount": "Discount",
      "btn": {
        "addFrame": "add frame",
        "checkout": "Place your order",
        "freeCheckout": "Place your order (it's free)",
        "getShopping": "Get shopping"
      },
      "delayedShipping": "Shipping within 7 - 9 working days",
      "discount": "Discount ({code})",
      "empty": "There is nothing in your cart.<br> Start shopping to fill it up!",
      "free": "Free",
      "htoCounter": "<strong>{count} of {max}</strong> frames chosen",
      "htoTitle": "Home Try-on",
      "shipping": "Shipping within 3 - 5 working days",
      "title": "Cart",
      "total": "Total"
    },
    "category": {
      "clipOns": {
        "title": "Clip-on"
      },
      "glasses": {
        "description": "Our Blue Light Filter is a game-changer for those who\nlive in front of screens. In October, add it to\nany pair of glasses, at no extra cost.\n",
        "subtitle": "Pick your favorites, starting at <strong>{price}</strong>,\nprescription lenses included.\n",
        "title": "Glasses"
      },
      "sunglasses": {
        "subtitle": "Pick your favorites, starting at <strong>{price}</strong>,\nUV protection lenses included.\n",
        "title": "Sunglasses"
      }
    },
    "checkout": {
      "address": {
        "label": {
          "address1": "Street Address",
          "address2": "Apt/Suite",
          "city": "City",
          "company": "Company",
          "companyNumber": "VAT Number",
          "county": "County",
          "firstName": "First Name",
          "lastName": "Last Name",
          "phone": "Phone",
          "zip": "Zip"
        }
      },
      "billing": {
        "btn": {
          "save": "Continue",
          "skip": "Skip, add it later"
        },
        "option": {
          "differentAddress": "Add a different one",
          "sameAsShipping": "Same as shipping address"
        },
        "title": "Add billing address"
      },
      "confirmation": {
        "cardPayment": "Card authorization",
        "description": "The home try-on service is free. Your bank card details are\nrequired for validation only. For authorisation, we'll temporarily\nblock a minimum amount of 1 RON. You will only be charged if\nyou do not return the frames.\n",
        "title": "Add your card details for confirmation"
      },
      "index": {
        "btn": {
          "login": "I have an account",
          "save": "Continue"
        },
        "description": "Fill in your email address to complete your order.",
        "label": {
          "aggrement": "By submitting your order, you agree with",
          "and": "and",
          "code": "Enter Code",
          "email": "Email Address",
          "privacy": "Privacy Policy",
          "terms": "Terms of Service"
        },
        "notice": {
          "password": "We've sent you an email with the 4-digit verification code."
        },
        "title": "You're almost there"
      },
      "payment": {
        "btn": {
          "apply": "Apply",
          "redeemCode": "Redeem gift card or promo code",
          "remove": "Remove",
          "save": "Complete order"
        },
        "cardPayment": "Pay with the card",
        "issues": "We are currently experiencing issues with payment processing\non our website. We will fix this as soon as possible. Please\ncome back later if you want to place an order online.\n",
        "label": {
          "cardCode": "CVV",
          "cardExpiryMonth": "Month",
          "cardExpiryYear": "Year",
          "cardNumber": "Card Number",
          "discountCode": "Code"
        },
        "tdsTitle": "Confirmation",
        "title": "Payment method"
      },
      "prescription": {
        "btn": {
          "save": "Continue",
          "skip": "Skip, add it later",
          "upload": "Upload a photo..."
        },
        "error": {
          "size": "Size must not be greater then 5MB",
          "type": "Accepted extensions: jpg, png, gif or pdf"
        },
        "label": {
          "add": "ADD",
          "axis": "AXIS",
          "cylinder": "CYL",
          "pupilDistance": "Pupillary Distance (PD)",
          "sphere": "SPH"
        },
        "option": {
          "fillIn": "Fill in your prescription",
          "sendLater": "Send it later (we'll call you)",
          "uploadPhoto": "Upload a photo of your prescription"
        },
        "title": "Add prescription"
      },
      "shipping": {
        "btn": {
          "complete": "Complete order",
          "save": "Continue"
        },
        "title": "Add shipping address"
      },
      "step": {
        "billing": "Billing",
        "confirmation": "Confirmation",
        "payment": "Payment",
        "prescription": "Prescription",
        "shipping": "Shipping"
      },
      "title": "Checkout"
    },
    "countdown": {
      "days": "days",
      "hours": "hrs",
      "minutes": "min",
      "seconds": "sec",
      "title": "Free blue light filter!"
    },
    "cta": {
      "explore": {
        "end": "",
        "men": "men",
        "or": "or",
        "start": "See the collection for",
        "women": "women"
      }
    },
    "error": {
      "description": "But don't worry, we're working on it.<br> Please try again in a few minutes.",
      "title": "Ooops. Something went wrong."
    },
    "eyeTest": {
      "btn": {
        "book": "Book now",
        "schedule": "Book now",
        "unavailable": "Temporary unavailable"
      },
      "customer": {
        "title": "Fill in your details"
      },
      "header": {
        "subtitle": "The consultation only takes 20 minutes and it’s entirely free, with no obligation to purchase.",
        "title": "Free eye tests"
      },
      "label": {
        "email": "Email Address",
        "firstName": "First Name",
        "lastName": "Last Name",
        "phone": "Phone"
      },
      "pageTitle": "Free eye tests",
      "slots": {
        "title": "Select an exam time"
      },
      "step": "Step {current} of {max}"
    },
    "footer": {
      "label": {
        "services": "Services",
        "shop": "Shop"
      },
      "legal": {
        "anpc": "ANPC",
        "copyright": "&copy; {year} LUNET",
        "privacy": "Privacy",
        "terms": "Terms"
      },
      "newsletter": {
        "confirmation": "Thank you for subscribing!",
        "description": "Get the newest styles, offers and events by signing up to our awesome newsletter.",
        "email": "Enter your email address",
        "title": "Newsletter"
      },
      "stores": "Stores"
    },
    "giftCard": {
      "btn": {
        "addToCart": "Add to cart",
        "faq": "e-Card FAQs",
        "shopNow": "Choose e-Card"
      },
      "deliveryDate": {
        "subtitle": "Just pick a date and we'll definitely send it by email at the right moment.",
        "title": "When should we send it?"
      },
      "header": {
        "subtitle": "Make someone's day with our e-Card.\nPerfect for anyone close to you who's looking\nfor new eyeglasses or why not, sunglasses.\n",
        "title": "A great gift idea"
      },
      "label": {
        "giftNotes": "Add a note (optional)",
        "giftRecipient": "Recipient's Name",
        "giftRecipientEmail": "Recipient's Email",
        "giftSender": "Your Name"
      },
      "pageTitle": "Gift Card",
      "products": {
        "title": "Choose your type of e-Card"
      },
      "recipient": {
        "subtitle": "Give us some info about the lucky recipient and write something nice to go with the gift.",
        "title": "Who's the lucky one?"
      },
      "step": "Step {current} of {max}"
    },
    "help": {
      "blueLightFilter": "#### What is blue light?\nBlue light is a high-energy light that digital screens emit. We're exposed\nto it every day, especially if we work long hours in front of a computer screen\nor if we binge-watch all 9 seasons of Seinfeld in one go. And that's where\nblue light filtering glasses might help.\n#### Does blue light hurt my eyes?\nThe flickering blue light coming from digital screens casts a glare that reduces\nyour visual contrast, affecting clarity and sharpness. Besides that, increased\nexposure to blue light may be linked to eye fatigue, headaches and sleep cycle disruption.\n#### How do I protect my eyes?\nYou can reduce your blue light exposure by adding a blue light filter to a\npair of new glasses when you select the lenses for your frames.\n#### Don't wear glasses?\nNo problem. The blue light filter is also available without an eyeglass\nprescription. So now you have a good reason to wear glasses. Finally!\n",
      "giftCard": "#### How do I use my Lunet e-Card?\nSimply enter the code on the e-Card at checkout.\n#### Can I return an e-Card?\nOur e-Cards cannot be returned or refunded.\n#### Does my e-Card have an expiration date?\nNope, not really. It's indefinite, just like... well,\nwe can't think of many indefinite things right now,\nbut our e-Card is definitely one of them.\n#### How does the e-Card work?\nIt works like printed gift cards, only this is {year} and paper &\nplastic are not a thing anymore. So we send it by email.\n#### When will the e-Card be delivered?\nWhenever you feel like it, we're flexible* like this! If it's\nthe same day - we will send it in an hour. If it's not -\nthe morning of the date of your choice.\n",
      "homeTryOn": "#### What is Home Try-on?\nHome Try-on is a free service where you can\ntry up to 4 different frames at home.\n#### How do I choose my Home Try-on frames?\nChoose your favorite frames, add them to cart via the \"Try at home\nfor free\" button, fill in your e-mail, name, delivery address and\nsubmit your order. We’ll take it from there.\n####  How long can I keep the frames?\nFour days, starting from the moment you receive them.\n####  Can I keep the frames I like?\nNot yet! At this stage, the frames come equipped with demo\nlenses. So, you'll have to send them back to us - don't worry,\nwe'll take care of the shipping costs.\n#### How do I get my favorite glasses?\nIf you find what you are looking for, just place a regular order\non our website. We'll send them over, free delivery and lenses included.\n####  What if none of the frames fits me?\nDon't worry, we're constantly working on new designs. So, keep in touch\nfor latest releases and get the most out of our Home Try-on service.\nDon't forget: you can always try new glasses without extra shipping costs.\n#### How can I return the frames?\nAfter 4 days, when the trial period is over, a courier will contact\nyou for pick-up. Please put all the frames back in their box and look\nfor the AWB tracking code inside. In case you want to change your\nreturn details, feel free to call us at: +40 (728) 547 184.\n",
      "lenses": "#### What type of lenses do you offer?\nAll our glasses come with thin lenses with UV400 protection,\nanti-reflective and anti-scratch coating based on your prescription.\n#### Can high prescriptions be handled?\nWe offer lenses with prescriptions ranging between -10 and +10. The lenses will\nbe thinned, so that they fit your frame. For prescriptions over 6, we recommend\nour premium lenses.\n#### What are Single Vision lenses?\nSingle vision lenses offer only ONE type of vision correction.\nThis means they work for people that are farsighted (-) or nearsighted (+).\nThey are the world's most widespread form of vision correction in glasses.\n#### What are Non Prescription lenses?\nNo vision correction, just protection from dust and blue\nlight coming from direct sunlight or digital screens. Anti-scratch\nand anti-reflective coating included.\n#### What are premium lenses?\nPremium lenses are ultra-thin lenses. We recommend them for people with prescriptions >4\nfor metal frames and >6 for acetate frames. In addition, premium lenses are treated\nwith a Quarz hardening coating.\n#### Can I add computer screen protection?\nYes, for those who spend long hours in front of screens and\nmonitors Blue Light Filter can be added as an extra option.\n#### How do I add my prescription?\nIf you opt for prescription lenses, then you will add your prescription at the end,\nin the checkout page. You can do this in two different ways: either you upload a photo\nwith your prescription or you fill in the lenses specifications in the dedicated form.\n",
      "section": {
        "blueLightFilter": "Blue Light Filter",
        "giftCard": "e-Card",
        "homeTryOn": "Home Try-on",
        "lenses": "Lenses"
      },
      "title": "Info"
    },
    "homeTryOn": {
      "btn": {
        "faq": "How it works?"
      },
      "subtitle": "Try {count} frames in the comfort of your home.",
      "title": "Home Try-on"
    },
    "index": {
      "cover": {
        "btn": {
          "men": "Shop Men",
          "more": "Discover",
          "women": "Shop Women"
        },
        "description": "hello, free blue filter!\n",
        "title": "Explore the collection"
      },
      "reviews": {
        "btn": "Read them here"
      },
      "services": {
        "eyeTest": "Free eye tests",
        "homeTryOn": "Home Try-on",
        "stores": "Stores"
      }
    },
    "invoice": {
      "label": {
        "discount": "Discount",
        "item": "Item",
        "orderNumber": "Order number",
        "price": "Price",
        "total": "Total",
        "totalVat": "Total VAT",
        "vat": "VAT",
        "vatRate": "VAT Rate"
      },
      "title": "Invoice"
    },
    "jobs": {
      "btn": {
        "apply": "Apply"
      },
      "operator": {
        "form": "https://airtable.com/appm26rZbEvalkDF0/shrrNaJwvSx8EwcvU",
        "title": "Operator comenzi"
      },
      "optometrist": {
        "form": "https://airtable.com/appm26rZbEvalkDF0/shrvQLstfYGMLgAsU",
        "title": "Optometrist"
      },
      "salesAssistant": {
        "form": "https://airtable.com/appm26rZbEvalkDF0/shr6nJMd5Ql8wBKl4",
        "title": "Asistent vânzări"
      },
      "title": "Jobs"
    },
    "journal": {
      "description": "With Lunet, our main focus is to build a fresh new visual territory and\ncustomer experience, by placing people first, and drawing inspiration\nfrom local artists and creative community.\n",
      "title": "Journal"
    },
    "lensColor": {
      "brown": "Brown",
      "greenG15": "Green",
      "grey": "Grey"
    },
    "location": {
      "title": "{city} store"
    },
    "login": {
      "btn": {
        "login": "Login"
      },
      "label": {
        "code": "Enter Code",
        "email": "Email Address"
      },
      "notice": {
        "password": "We've sent you an email with the 4-digit verification code."
      },
      "title": "Login"
    },
    "menu": {
      "clipOns": "Clip-on",
      "eyeTest": "Free eye tests",
      "glasses": "Glasses",
      "new": "New!",
      "sunglasses": "Sunglasses"
    },
    "nav": {
      "about": "About",
      "account": "Account",
      "clipOns": "Clip-on",
      "eyeTest": "Free eye tests",
      "giftCards": "Gift card",
      "glasses": "Glasses",
      "homeTryOn": "Home Try-on",
      "jobs": "Jobs",
      "men": "Shop Men",
      "press": "Press",
      "stores": "Stores",
      "sunglasses": "Sunglasses",
      "women": "Shop Women"
    },
    "notification": {
      "cookies": {
        "btn": "Ok",
        "link": "Learn more",
        "title": "This website uses <span class=\"underline\">cookies</span>."
      }
    },
    "order": {
      "btn": {
        "editAddress": "Change shipping address",
        "instagram": "Follow us",
        "printInvoice": "Print invoice",
        "trackPackage": "Track your package"
      },
      "canceled": {
        "description": "Your order was canceled on {date}. Please contact us\nif you have any questions.\n",
        "title": "Your order has been cancelled"
      },
      "confirmed": {
        "description": "A confirmation email containing all the details of your order has been\nsent to <strong>{email}</strong>. You can come back to this page anytime\nfor updates on your shipment status.\n",
        "title": "Your order is confirmed"
      },
      "delivered": {
        "description": "Your order has been shipped to the designated address. If you have any\nquestions or need help, please contact us at <strong>{email}</strong>.\n",
        "return": "At the end of the trial, <strong>on {date}</strong>, a courier will contact\nyou for pick-up. Place all the frames in their original package and look\nfor the AWB inside. In case you wish to change the pick-up address or\nreschedule you can call this number: <strong>+40 (728) 547184</strong>.\nYou will not be charged for the return.\n",
        "title": "Your order has been shipped"
      },
      "feedback": {
        "btn": {
          "back": "Back to site",
          "send": "Send"
        },
        "label": {
          "note": "If you'd like to add anything else, write your thoughts here",
          "sent": "Sent!"
        },
        "title": "Thanks for your feedback."
      },
      "overview": {
        "label": {
          "deliveryDate": "Delivery Date",
          "giftCard": "e-Card",
          "notes": "Notes",
          "recipient": "Recipient",
          "sender": "Sender",
          "shippingAddress": "Shipping To"
        }
      },
      "pickup": {
        "description": "The wait is over! Your glasses are ready, and they're waiting\nfor you at our showroom. Come pick them up during open hours.\n",
        "title": "Good things are waiting for you"
      },
      "shipping": {
        "btn": {
          "save": "Save changes"
        },
        "title": "Shipping address"
      },
      "summary": {
        "label": {
          "autoDiscount": "Discount",
          "discount": "Discount ({code})",
          "free": "Free",
          "hto": "Home Try-on",
          "shipping": "Shipping",
          "total": "Total"
        }
      },
      "title": "Thank you!",
      "virtual": {
        "description": "A confirmation email with all the details of your order has been sent to\n<strong>{email}</strong>. You can come back to this page anytime\nfor updates on your e-Card delivery status.\n",
        "title": "Your order is confirmed"
      }
    },
    "press": {
      "description": "With Lunet, our main focus is to build a fresh new visual territory and\ncustomer experience, by placing people first, and drawing inspiration\nfrom local artists and creative community.\n",
      "title": "Press"
    },
    "product": {
      "autoDiscount": {
        "blueLightFilter": "Free in {month}",
        "lightResponsiveFilter": "{value} off in {month}",
        "singleVisionLenses": "{value} off in {month}."
      },
      "benefits": {
        "case": "Custom made case & cloth",
        "coating": "Anti-reflective & anti-scratch coating",
        "eyeTest": "Free eye-tests in our",
        "guarantee": "Two years guarantee on frame & lenses",
        "lenses": {
          "clipOns": "UV protection lenses",
          "glasses": "Thin prescription lenses",
          "sunglasses": "UV protection lenses"
        },
        "return": "30 days free return policy",
        "shipping": "Free shipping & returns in",
        "store": "stores",
        "title": "Benefits",
        "uv": "UV400 protection"
      },
      "btn": {
        "addToCart": "Add to cart",
        "chooseLenses": "Select lenses and purchase",
        "fullHomeTryOn": "Home try-on is full",
        "inHomeTryOn": "In your home try-on",
        "more": "Find out more",
        "nextStep": "Next step",
        "reviews": "Reviews",
        "tryAtHome": "Try at home for free"
      },
      "details": {
        "accessories": {
          "description": "Magnetic case and cleaning cloth, made from recycled materials.",
          "title": "Accessories included"
        },
        "frame": {
          "description": "{material, select,\n  acetate {Made from a combination of cellulose acetate and a plasticizing agent of vegetable origin.}\n  combi {Made from a combination of cellulose acetate and a plasticizing agent of vegetable origin.}\n  metal {Made of stainless steel, resistant to chemical or corrosive agents, recyclable.}\n}\n",
          "title": "{material, select, acetate {Acetate} metal {Stainless steel} combi {Acetate}} frame"
        },
        "guarantee": "Two years guarantee<br> on frame & lenses",
        "lenses": {
          "description": "{category, select,\n  glasses {Thinned, regardless of the sphere or cylinder size. Treated with anti-glare and anti-scratch filters.}\n  sunglasses {Offers protection against UV rays. Treated with anti-glare and anti-scratch filters.}\n}\n",
          "title": "{category, select, glasses {Prescription} sunglasses {Sun}} lenses included"
        },
        "subtitle": "Each pair of glasses includes\n{category, select, glasses {prescription} sunglasses {UV protection}} lenses, frame case and cleaning cloth.\n",
        "title": "What you get"
      },
      "htoPopover": {
        "description": "We're running low on stock with this model, so\nunfortunately it is not available for try-on.\n",
        "title": "Home Try-on unavailable"
      },
      "label": {
        "addFilter": "Add extra options",
        "clipOn": "clip-on",
        "clipOnPrice": "The price is for the<br> clip-on only",
        "free": "Free",
        "inStock": "In stock.",
        "lowInStock": "Low in stock.",
        "noPrescription": "You don't need a prescription<br> for these type of glasses.",
        "optional": "opțional",
        "outOfStock": "Out of stock.",
        "prescription": "You'll add your prescription<br> at checkout.",
        "price": "Prescription lenses<br> included",
        "selectLenses": "Choose lenses"
      },
      "pricing": {
        "brand": "Lunet",
        "costs": "Costs",
        "description": "We design the glasses in-house and work directly with our manufacturers. We cut out\nall the middlemen so we can offer quality glasses at an affordable price.\n",
        "retail": "Traditional retailers"
      },
      "similar": {
        "title": "You might also like..."
      },
      "sizes": {
        "extraLarge": "XL",
        "large": "L",
        "medium": "M",
        "small": "S"
      },
      "specs": {
        "frame": "{width, select, small {small} medium {Medium} large {Large}} size frame, made\nfrom {material, select, acetate {acetate} metal {stainless steel} combi {acetate}}\n",
        "measurements": "Frame measurements are {measurements}",
        "title": "Dimensions"
      },
      "title": "{title} in {variant} - {category, select, glasses {Glasses} sunglasses {Sunglasses} clip-ons {Clip-on}}"
    },
    "products": {
      "autoDiscount": "Buy <strong>2 pairs</strong> with <strong>15% off</strong>",
      "discountCode": "Use code <strong>{code}</strong> for <strong>{value}% off</strong>",
      "hto": {
        "btn": "Find out more",
        "description": "You can choose<br> up to {count} frames to try<br> at home for free.",
        "title": "Home Try-on"
      },
      "label": {
        "archiveSale": "Archive Sale",
        "backInStock": "back in stock",
        "eyeTests": "Book a free eye test",
        "glassesPrice": "<strong>{price}</strong>, lenses included",
        "lowInStock": "low in stock",
        "newInStock": "NEW",
        "outOfStock": "out of stock",
        "price": "<strong>{price}</strong>"
      },
      "nav": {
        "men": "Men",
        "women": "Women"
      },
      "title": "{gender, select, men {Men} women {Women}}'s\n{category, select, glasses {Glasses} sunglasses {Sunglasses}}\n"
    },
    "reviews": {
      "description": "Our overall rating is <strong>{nps} out of 5 stars</strong> from over {total} reviews.",
      "label": {
        "date": "Reviewed on {date}",
        "homeTryOn": "Home Try-on"
      },
      "title": "Client reviews"
    },
    "shop": {
      "description": "Romanian-based eyewear label that brings out the best in shopping for a pair of eyeglasses.",
      "name": "Lunet Eyewear"
    },
    "shops": {
      "pageTitle": "Shipping Country",
      "title": "Choose your shipping country"
    },
    "stores": {
      "btn": {
        "book": "Free eye test",
        "callUs": "Call us",
        "viewMap": "View map"
      },
      "label": {
        "contact": "Contact",
        "openNow": "Open today until {endHour}",
        "openToday": "Open today from {startHour}",
        "opensAt": "Open on {weekday} from {startHour}",
        "schedule": "Opening Hours",
        "services": "Services"
      },
      "subtitle": "A relaxed and enjoyable atmosphere where you can try on the entire eyewear collection.",
      "title": "Stores"
    },
    "surcharge": {
      "blueLightFilter": {
        "description": "Protects against blue light and combats eye fatigue.",
        "title": "Blue light filter"
      },
      "clipOn": {
        "title": "Clip-on"
      },
      "degressiveLenses": {
        "description": "For people that are farsighted (-) and nearsighted (+).",
        "title": "Lentile degresive"
      },
      "highIndex": {
        "description": "Ultra-thin lenses, treated with Quartz filter, recommended for prescriptions over -/+4.",
        "title": "Premium lenses"
      },
      "lightResponsiveFilter": {
        "description": "Darkens up to 85% and is recommended in cases of increased light sensitivity.",
        "title": "Light responsive"
      },
      "noPrescription": {
        "description": "For protection, no prescription needed for these lenses.",
        "title": "Non prescription"
      },
      "polarizingFilter": {
        "description": "Reduces reflections and glare from surfaces. Also recommended for driving.",
        "title": "Polarizing filter"
      },
      "progressiveLenses": {
        "description": "For people that are farsighted (-) and nearsighted (+).",
        "title": "Progressive lenses"
      },
      "singleVisionLenses": {
        "description": "For people that are farsighted (-) or nearsighted (+).",
        "title": "Single vision lenses"
      }
    }
  };
  _exports.default = _default;
});